import http from "./httpService";
import { apiUrl } from "../config.json";

export function addUser(data) {
  return http.post(`${apiUrl}/v1/users`, data);
}

export function getUsers(data) {
  return http.get(`${apiUrl}/v1/users`);
}

export function getUsersOption(data) {
  return http.get(`${apiUrl}/v1/users/options`);
}

export function getUserById(id) {
  return http.get(`${apiUrl}/v1/users/${id}`);
}

export function editUser(id, data) {
  return http.put(`${apiUrl}/v1/users/${id}`, data);
}

export function deleteUser(id) {
  return http.delete(`${apiUrl}/v1/users/${id}`);
}

export function accountVerification(user) {
  return http.post(`${apiUrl}/v1/users/account-verification`, user);
}

export function settlementProgram(data) {
  return http.post(`${apiUrl}/v1/users/settlement-program`, data);
}

export function changePassword(data) {
  return http.post(`${apiUrl}/v1/users/change-password`, data);
}

export function forgotPassword(data) {
  return http.post(`${apiUrl}/v1/users/forgot-password`, data);
}

export function resetPassword(token, data) {
  return http.put(`${apiUrl}/v1/users/reset-password/${token}`, data);
}

export function uploadFile(data) {
  return http.post(`${apiUrl}/v1/users/upload-file`, data);
}

export function resendVerificationEmail(id) {
  return http.post(`${apiUrl}/v1/users/resend-verification/${id}`);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  addUser,
  getUsers,
  getUsersOption,
  getUserById,
  editUser,
  deleteUser,
  accountVerification,
  settlementProgram,
  changePassword,
  forgotPassword,
  resetPassword,
  uploadFile,
  resendVerificationEmail,
};

import React, { useEffect, useState } from "react";
import "react-image-lightbox/style.css";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "./scss/style.scss";
import authService from "./services/authService";
import { history } from "./_helpers/history";
import AdminProtectedRoute from "./_components/AdminProtectedRoute";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Admin Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const TataTertib = React.lazy(() =>
  import("./views/pages/tataTertib/TataTertib")
);
const Page403 = React.lazy(() => import("./views/pages/page403/Page403"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

export function App() {
  const [user, setUser] = useState({});

  useEffect(() => {
    const user = authService.getCurrentUser();
    setUser({ user });
  }, []);

  return (
    <BrowserRouter history={history}>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route
            exact
            path="/login"
            name="Login Page"
            render={(props) => <Login {...props} user={user} />}
          />
          <Route path="/tata-tertib" component={TataTertib} />
          <Route
            exact
            path="/403"
            name="Page 403"
            render={(props) => <Page403 {...props} />}
          />
          <Route
            exact
            path="/404"
            name="Page 404"
            render={(props) => <Page404 {...props} />}
          />
          <Route
            exact
            path="/500"
            name="Page 500"
            render={(props) => <Page500 {...props} />}
          />
          <AdminProtectedRoute
            path="/"
            name="Admin"
            render={(props) => <TheLayout {...props} />}
          />
          <Redirect to="/dashboard" />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
}

export default App;

import { Redirect, Route } from "react-router-dom";
import authService from "../services/authService";
import userService from "../services/userService";
import React, { useEffect } from "react";
import { secretKey } from "../config.json";
import CryptoJS from "crypto-js";

const AdminProtectedRoute = ({
  path,
  component: Component,
  render,
  ...rest
}) => {
  const user = authService.getCurrentUser();
  const refreshToken = authService.getRefreshToken();

  useEffect(() => {
    async function checkStatus() {
      try {
        const { data } = await userService.getUserById(user.id);
        // Decrypt
        var bytes = CryptoJS.AES.decrypt(data.payload, secretKey);
        var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        if (!decryptedData.disclaimer) {
          window.location = "/tata-tertib";
        }
      } catch (ex) {
        console.log(ex);
      }
    }

    checkStatus();
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!user) {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          );
        }

        if (refreshToken && Date.now() >= refreshToken.exp * 1000) {
          authService.logout();
          window.location = "/login";
        }

        if (!refreshToken) {
          authService.logout();
          window.location = "/login";
        }

        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
};

export default AdminProtectedRoute;
